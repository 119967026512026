import React from 'react';

const InquiryFormFr = () => {
    return (
        <div className="flex items-center justify-center bg-warm">
            <div className="bg-warm p-10 rounded-lg max-w-2xl w-1/2 mx-auto lg:px-20">
                <h2 className="text-2xl font-bold text-center mb-4">Demande de renseignements</h2>
                <p className="text-center text-gray-500 mb-6">Découvrez ce que les autres ont dit !</p>
                <form>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div className="flex flex-col">
                            <label htmlFor="first-name" className="mb-2 font-normal">Prénom</label>
                            <input
                                type="text"
                                id="first-name"
                                className="border border-warm-light rounded-md p-3 focus:outline-none focus:ring-2 "
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="last-name" className="mb-2 font-normal">Nom de famille</label>
                            <input
                                type="text"
                                id="last-name"
                                className="border border-gray-300 rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-black"
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div className="flex flex-col">
                            <label htmlFor="mobile-number" className="mb-2 font-normal">Numéro de téléphone mobile</label>
                            <input
                                type="text"
                                id="mobile-number"
                                className="border border-gray-300 rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-black"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="email" className="mb-2 font-normal">Adresse électronique</label>
                            <input
                                type="email"
                                id="email"
                                className="border border-gray-300 rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-black"
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="message" className="mb-3 font-normal">
                            Quels sont les sujets ou les services pour lesquels vous aimeriez que Coachello vous assiste ?
                        </label>
                        <textarea
                            id="message"
                            rows={4}
                            className="border border-gray-300 rounded-md mt-2 p-3 w-full focus:outline-none focus:ring-2 focus:ring-black"
                        />
                    </div>
                    <div className="text-center">
                        <button
                            type="submit"
                            className="items-center justify-center gap-4 px-8 py-3 border-0 cursor-pointer bg-black rounded-3xl text-white hover:outline-white hover:bg-white"

                        >
                            Envoyer un message
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default InquiryFormFr;
