import React from "react";
// @ts-ignore
import slide1Img from "../../../assets/img/coaches/jordan.png";
// @ts-ignore
import slide2Img from "../../../assets/img/coaches/adeline.png";
// @ts-ignore
import slide3Img from "../../../assets/img/coaches/lisa.png";
// @ts-ignore
import slide4Img from "../../../assets/img/coaches/christie.png";
import useCarousel from "../../../hooks/useCarousel";
// @ts-ignore
import arrowLeft from "../../../assets/img/arrow-left.png";
// @ts-ignore
import arrowRight from "../../../assets/img/arrow-right.png";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import VisibilitySensor from "react-visibility-sensor";

const WallFr = () => {
    const slides = [
        {
            title: "“Progrès incroyables”",
            des: "C'est ma première expérience de coaching et je dois dire : après seulement quelques séances, j'ai ressenti une véritable évolution. Mon coach a été exceptionnel et l'équipe de Coachello était toujours là pour me soutenir.",
            profile: slide1Img,
            userName: "Jordan Chenevier-Truchet",
            userDes: "CMO de Germinal",
        },
        {
            title: "“Impact mesurable”",
            des: "Une équipe très réactive, une application super pratique, et des employés très heureux. Chez Gorgias, nous sommes guidés par les données et mesurons l'impact. Coachello est en haut de la liste parmi toutes les solutions que nous avons vues jusqu'à présent.",
            profile: slide2Img,
            userName: "Adeline Bodemer",
            userDes: "Responsable du personnel Gorgias",
        },
        {
            title: "“Résultats instantanés”",
            des: "Après seulement quelques heures, j'ai eu le sentiment d'avoir beaucoup progressé en prise de parole en public. Mon coach a pris le temps de comprendre mes besoins et mes attentes. Ce fut un plaisir de travailler avec toute l'équipe de Coachello.",
            profile: slide3Img,
            userName: "Lisa Lachkar",
            userDes: "Directrice régionale de Riskified",
        },
        {
            title: "“Résultats tangibles”",
            des: "J'ai évolué à la fois professionnellement et personnellement durant les cours et j'ai même eu l'occasion de le tester en donnant une formation à mes collègues aujourd'hui. Je recommande vivement la plateforme Coachello.",
            profile: slide4Img,
            userName: "Christie Jones",
            userDes: "Manager chez Tokyo Marine HCC",
        },
    ];

    const {
        currentIndex,
        goToSlide,
        startAutoPlay,
        pauseAutoPlay,
        goToNextSlide,
        goToPreviousSlide,
    } = useCarousel({
        totalSlides: slides.length - 2,
        autoPlayInterval: 5000,
    });
    const handleOnVisible = (isVisible: boolean) => {
        if (isVisible) {
            startAutoPlay();
        } else {
            pauseAutoPlay();
        }
    };
    return (
        <div className="section bg-warm">
            <div className="text-center">
                <h2 className="mt-3">Le mur de l'amour</h2>
                <p className="text-sm text-gray mb-10">Découvrez ce que les autres ont dit!</p>
            </div>
            <VisibilitySensor onChange={handleOnVisible} delayedCall>
                <div className="flex items-center">
                    <div className="px-8 relative overflow-hidden md:overflow-auto">
                        <img
                            onClick={goToPreviousSlide}
                            className="absolute left-0 my-auto w-8 h-8 top-1/2 bottom-1/2"
                            src={arrowLeft}
                        />
                        <img
                            onClick={goToNextSlide}
                            className="absolute right-0 my-auto w-8 h-8 top-1/2 bottom-1/2"
                            src={arrowRight}
                        />

                        <div className="overflow-hidden flex ">
                            <div
                                className="flex transition-transform duration-500"
                                style={{
                                    transform: `translateX(-${(currentIndex * 100) / 3}%)`,
                                }}
                            >
                                {slides.map((slide, index) => (
                                    <div
                                        key={index}
                                        className="w-full px-2  "
                                        style={{ flex: `0 0 ${100 / 3}%` }}
                                    >
                                        <div
                                            className={`rounded-2xl p-6 h-full ${index % 2 == 1 ? "bg-white" : "bg-white"
                                                }`}
                                        >
                                            <h5 className="my-3">{slide.title}</h5>
                                            <p className="text-sm mb-5">{slide.des}</p>
                                            <div className="flex items-center gap-5">
                                                <img
                                                    src={slide.profile}
                                                    alt="Profile"
                                                    className="w-8 h-8 rounded-full"
                                                />
                                                <div className="">
                                                    <p className="font-normal my-1 text-sm">
                                                        {slide.userName}
                                                    </p>
                                                    <p className="text-gray my-1 text-sm">
                                                        {slide.userDes}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </VisibilitySensor>
            <br />
            <div className="flex gap-3 items-center justify-center">
                {Array.from({ length: slides.length - 2 }, (_, index) => (
                    <span
                        key={index}
                        onClick={() => goToSlide(index)}
                        className={`rounded-full w-6 h-6 ${currentIndex === index ? "bg-orange" : "bg-gray"
                            }`}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default WallFr;
