import React, { useState } from 'react';
// @ts-ignore
import Stat from '../../../assets/img/seo/Stat.png';
// @ts-ignore
import Badge from '../../../assets/img/seo/Badge.png';
// @ts-ignore
import ROI from '../../../assets/img/seo/ROI.png';
import "../SEO.css";

const WhyFr = () => {
    const [step, setStep] = useState(1);
    const [selectedPersons, setSelectedPersons] = useState('');
    const [selectedSession, setSelectedSession] = useState('');
    const [selectedCoachingSessions, setSelectedCoachingSessions] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const nextStep = () => {
        if (step === 1 && selectedPersons) {
            setStep(step + 1);
        } else if (step === 2 && selectedSession) {
            setStep(step + 1);
        } else if (step === 3) {
            setStep(step + 1);
        } else if (step === 4 && selectedCoachingSessions) {
            setStep(step + 1);
        }
    };

    const prevStep = () => setStep(step - 1);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setIsSubmitted(true);
    };

    return (
        <div>
            <div className="flex p-10 bg-white">
                <div className="flex-1 p-10 justify-center items-center">
                    <h2 className="text-gray-800 font-bold text-lg mb-6">Pourquoi Coachello ?</h2>

                    <div className="mb-5 p-5 border rounded-md flex items-start bg-white">
                        <img src={Badge} alt="Icon 1" className="mr-4 w-20 h-20" />
                        <div>
                            <h3 className="text-2xl font-bold">4.9/5</h3>
                            <p className="font-semibold text-gray-600">Satisfaction</p>
                            <p className="text-gray-500 text-sm">Satisfaction moyenne des coachés quant à l'adéquation et aux progrès individuels.</p>
                        </div>
                    </div>

                    <div className="mb-5 p-5 border rounded-md flex items-start bg-white">
                        <img src={Stat} alt="Icon 2" className="mr-4 w-20 h-20" />
                        <div>
                            <h3 className="text-2xl font-bold">92%*</h3>
                            <p className="font-semibold text-gray-600">Progrès perçus</p>
                            <p className="text-gray-500 text-sm">
                                Du début à la fin de leur programme de coaching, y compris les comportements observables.                            </p>
                        </div>
                    </div>

                    <div className="p-5 border rounded-md flex items-start bg-white">
                        <img src={ROI} alt="Icon 3" className="mr-4 w-20 h-20" />
                        <div>
                            <h3 className="text-2xl font-bold">2x</h3>
                            <p className="font-semibold text-gray-600">Plus efficace</p>
                            <p className="text-gray-500 text-sm">Grâce à une approche fondée sur l'IA et les données</p>
                        </div>
                    </div>

                    <p className="text-sm text-gray-500 mt-3">*Les résultats ci-dessus sont basés sur plus de 2000 coachés.</p>
                </div>

                <div className="flex-1 p-10 mt-10 w-full justify-center items-center">
                    <div className="bg-warm p-10 rounded-xl shadow-md">
                        {isSubmitted ? (
                            <div>
                                <h2 className="text-xl font-bold mb-5 text-center">Succès!</h2>
                                <p className="text-center">Votre devis a été soumis avec succès !</p>
                            </div>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                {step === 1 && (
                                    <div>
                                        <h2 className="text-xl font-bold mb-5 text-center">Obtenez un devis personnalisé !</h2>
                                        <div className="space-y-4 mb-5">
                                            {['Niveaux C', 'Cadres supérieurs', 'Les meilleurs talents', 'Contributeurs individuels'].map((option, index) => (
                                                <label key={index} className="flex items-center space-x-3 p-4 mb-2 bg-white rounded-lg shadow-sm">
                                                    <input
                                                        type="radio"
                                                        name="persons"
                                                        value={option}
                                                        className="form-radio h-5 w-5 mr-2 text-pink-600 peer"
                                                        onChange={(e) => setSelectedPersons(e.target.value)}
                                                    />
                                                    <span className="peer-checked:text-pink-600">{option}</span>
                                                </label>
                                            ))}
                                        </div>

                                        <div className='flex items-center justify-center'>
                                            <button
                                                className="w-1/3 flex items-center justify-center gap-4 px-8 py-3 border-0 cursor-pointer bg-pink rounded-3xl hover:outline"
                                                onClick={nextStep}
                                                disabled={!selectedPersons}
                                            >
                                                Commencer →
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {step === 2 && (
                                    <div>
                                        <h2 className="text-xl font-bold mb-5 text-center">Combien de personnes sont concernées ?</h2>
                                        <div className="space-y-4 mb-5">
                                            {['1', '2 - 10', '10 - 50', 'Provide exact number (open field)'].map((option, index) => (
                                                <label key={index} className="flex items-center space-x-3 p-4 mb-2 bg-white rounded-lg shadow-sm">
                                                    <input
                                                        type="radio"
                                                        name="persons"
                                                        value={option}
                                                        className="form-radio h-5 w-5 mr-2 text-pink-600 peer"
                                                        onChange={(e) => setSelectedSession(e.target.value)}
                                                    />
                                                    <span className="peer-checked:text-pink-600">{option}</span>
                                                </label>
                                            ))}
                                        </div>
                                        <div className='flex items-center justify-center'>
                                            <button
                                                className="w-1/3 flex items-center justify-center gap-4 px-8 py-3 border-0 cursor-pointer bg-pink rounded-3xl hover:outline"
                                                onClick={nextStep}
                                                disabled={!selectedSession}
                                            >
                                                Suivant →
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {step === 3 && (
                                    <div>
                                        <h2 className="text-xl font-bold mb-5 text-center">Comment recevoir vos sessions ?</h2>
                                        <div className="space-y-4">
                                            {['Numériquement', 'En personne', 'Hybride'].map((option, index) => (
                                                <label key={index} className="flex items-center space-x-3 p-4 mb-2 bg-white rounded-lg shadow-sm">
                                                    <input
                                                        type="radio"
                                                        name="session"
                                                        value={option}
                                                        className="form-radio h-5 w-5 mr-2 text-pink-600 peer"
                                                        onChange={(e) => setSelectedSession(e.target.value)}
                                                    />
                                                    <span className="peer-checked:text-pink-600">{option}</span>
                                                </label>
                                            ))}
                                        </div>
                                        <div className='flex items-center justify-center'>
                                            <button
                                                className="w-1/3 flex items-center justify-center gap-4 px-8 py-3 border-0 cursor-pointer bg-pink rounded-3xl hover:outline"
                                                onClick={nextStep}
                                            >
                                                Suivant →
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {step === 4 && (
                                    <div>
                                        <h2 className="text-xl font-bold mb-5 text-center">Combien de séances de coaching par personne au cours d'une année ?</h2>
                                        <div className="space-y-4">
                                            {['4', '8', 'Illimité'].map((option, index) => (
                                                <label key={index} className="flex items-center space-x-3 p-4 mb-2 bg-white rounded-lg shadow-sm">
                                                    <input
                                                        type="radio"
                                                        name="sessions"
                                                        value={option}
                                                        className="form-radio h-5 w-5 mr-2 text-pink-600 peer"
                                                        onChange={(e) => setSelectedCoachingSessions(e.target.value)}
                                                    />
                                                    <span className="peer-checked:text-pink-600">{option}</span>
                                                </label>
                                            ))}
                                        </div>
                                        <div className='flex items-center justify-center'>
                                            <button
                                                className="w-1/3 flex items-center justify-center gap-4 px-8 py-3 border-0 cursor-pointer bg-pink rounded-3xl hover:outline"
                                                onClick={nextStep}
                                                disabled={!selectedCoachingSessions}
                                            >
                                                Suivant →
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {step === 5 && (
                                    <div>
                                        <h2 className="text-xl font-bold mb-5 text-center">Vos coordonnées</h2>
                                        <div className="mb-4">
                                            <input
                                                type="text"
                                                placeholder="Votre nom"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                className="w-full px-4 py-2 border rounded-lg"
                                                required
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                type="email"
                                                placeholder="Votre email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="w-full px-4 py-2 border rounded-lg"
                                                required
                                            />
                                        </div>
                                        <div className='flex items-center justify-center'>
                                            <button
                                                type="submit"
                                                className="w-1/3 flex items-center justify-center gap-4 px-8 py-3 border-0 cursor-pointer bg-pink rounded-3xl hover:outline"
                                            >
                                                Soumettre →
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyFr;
