import { graphql } from "gatsby";
import React from "react";
import IntroFr from "./sections/IntroFr";
import WhyFr from "./sections/WhyFr";
import TrustedFr from "./sections/TrustedFr";
import WallFr from "./sections/WallFr";
import FAQFr from "./sections/FAQFr";
import CityFr from "./sections/CityFr";
import PricesFr from "./sections/PricesFr";
import HighlightsFr from "./sections/HighlightsFr";
import FooterFr from "../../sections/FooterFr";
import CoachesFr from "./sections/CoachesFr";
import InquiryFormFr from "./sections/InquireFr";

const CityPage = (data: any) => {
    const cityData = data?.data.prismicSeoPageType?.dataRaw;
    const city = data?.data.prismicSeoPageType?.uid;
    console.log(data, "cityData");

    const FAQData = [
        {
            title: "Quels sont les critères à prendre en compte pour choisir son coach professionnel?\n",
            content: (
                <>
                    <p>
                        {cityData.what_criteria_should_you_use_to_choose_your_professional_coach[0].text}
                    </p>
                </>
            ),
        },
        {
            title: "Quel est le juste prix pour un coach professionnel?\n",
            content: (
                <>
                    <p>
                        {cityData.what_is_the_right_price_for_a_professional_coach[0].text}
                    </p>
                </>
            ),
        },
        {
            title: "De combien de séances ai-je besoin?\n",
            content: (
                <>
                    <p>
                        {cityData.how_many_sessions_do_i_need[0].text}
                    </p>
                </>
            ),
        },
    ]

    return (
        <div>
            <IntroFr city={city.replace(/\b\w/g, (char: string) => char.toUpperCase())} />
            <WhyFr />
            <TrustedFr />
            <WallFr />
            <CoachesFr />
            <CityFr
                city={city.replace(/\b\w/g, (char: string) => char.toUpperCase())}
                information_city_website={cityData.information_city_website[0].text}
                coaching_market_website={cityData.coaching_market_website[0].text}
            />
            <PricesFr
                city={city.replace(/\b\w/g, (char: string) => char.toUpperCase())}
                individual_coaching={cityData.individual_coaching[0].text}
                group_coaching={cityData.group_coaching[0].text}
                executive_coaching={cityData.executive_coaching[0].text}
            />
            <FAQFr FAQData={FAQData} city={city.replace(/\b\w/g, (char: string) => char.toUpperCase())} />
            <HighlightsFr />
            <InquiryFormFr />
            <FooterFr />
        </div>
    );
};

export default CityPage;

export const query = graphql`
query CityPage($uid: String) {
    prismicSeoPageType(uid: { eq: $uid }) {
        id
        uid
        dataRaw
    }
}`;
