import React from 'react'
import "../SEO.css"

interface PricesProps {
    city: string;
    individual_coaching: string;
    group_coaching: string;
    executive_coaching: string;
}

const PricesFr: React.FC<PricesProps> = ({ city, individual_coaching, group_coaching, executive_coaching }) => {
    return (
        <div className="bg-white py-20 px-24">
            <h2 className="text-2xl font-bold text-center mb-6">Combien coûte le coaching à {city}?</h2>
            <div className="table-container">
                <div className="table">
                    <table className='w-full mx-10 border-collapse'>
                        <thead className="table-header bg-warm">
                            <tr>
                                <th className="border border-gray-300 p-4">Type de coaching</th>
                                <th className="border border-gray-300 p-4">{city}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="table-row">
                                <td className="border border-gray-300 bg-warm p-4">Coaching individuel</td>
                                <td className="border border-gray-300 bg-warm font-bold p-4">{individual_coaching}</td>
                            </tr>
                            <tr className="table-row">
                                <td className="border border-gray-300 bg-warm p-4">Coaching de groupe</td>
                                <td className="border border-gray-300 bg-warm font-bold p-4">{group_coaching}</td>
                            </tr>
                            <tr className="table-row">
                                <td className="border border-gray-300 bg-warm p-4">Coaching exécutif</td>
                                <td className="border border-gray-300 bg-warm font-bold p-4">{executive_coaching}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    )
}

export default PricesFr