import React, {HTMLAttributes} from 'react';
interface Props extends HTMLAttributes<HTMLImageElement>{
    triggerPx:number;
    srcSmall:string;
    srcLarge:string;
}
const Img = ({triggerPx,srcSmall,srcLarge,...rest}:Props) => {

    return (
        <picture>
            <source media={`(max-width: ${triggerPx-1}px)`} srcSet={`${srcSmall}`}/>
            <source media={`(min-width: ${triggerPx}px)`} srcSet={`${srcLarge}`}/>
            <img {...rest}  src={`${srcLarge}`} />
        </picture>
    );
};

export default Img;
