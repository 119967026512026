import React from 'react'
// @ts-ignore
import microsoftLogo from "../../../assets/img/companies/microsoft.png";
// @ts-ignore
import boltLogo from "../../../assets/img/companies/boltLogo.png";
// @ts-ignore
import gorgiasLogo from "../../../assets/img/companies/gorgiasLogo.png";
// @ts-ignore
import hylandLogo from "../../../assets/img/companies/hylandLogo.png";
// @ts-ignore
import meilleursLogo from "../../../assets/img/companies/meilleursLogo.png";
// @ts-ignore
import morningLogo from "../../../assets/img/companies/morningLogo.png";
// @ts-ignore
import enedisLogo from "../../../assets/img/companies/enedis.png";
// @ts-ignore
import qontoLogo from "../../../assets/img/companies/qontoLogo.png";
// @ts-ignore
import hecLogo from "../../../assets/img/companies/HEC.png";

const TrustedFr = () => {
    return (
        <div>
            <p className="mt-3 text-center text-xs">Confié par</p>
            <div className=" flex flex-wrap justify-center bg-warm-dark [&>div>img]:h-12">
                <div>
                    <img src={microsoftLogo} />
                </div>
                <div>
                    <img src={boltLogo} />
                </div>
                <div>
                    <img src={gorgiasLogo} />
                </div>
                <div>
                    <img src={hylandLogo} />
                </div>
                <div>
                    <img src={morningLogo} />
                </div>
                <div>
                    <img src={enedisLogo} />
                </div>
                <div>
                    <img src={qontoLogo} />
                </div>
                <div>
                    <img src={hecLogo} />
                </div>
            </div>
        </div>
    )
}

export default TrustedFr